const API_PATH = {
	BEES: "/v2/bees",
	AUTO_CRUD_CUSTOM_FIELDS: "/v2/autocrud/custom_fields",
	FILTER: "/filter"
};

const URL_PATH = {
	BEE_DETAILS: "/bees/detail/",
	MESSAGES: "/messages",
	BEE_EDIT: "/bees/edit/",
	CHECKOUT: "/reverselogistics/orders",
	AUDIT: "/admin/webhook"
};

export { API_PATH, URL_PATH };
