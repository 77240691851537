/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Result, Chat, Close, Edit, ShoppingCart, Settings, View, CopyLink, Download, Checkmark, OverflowMenuVertical } from "@carbon/icons-react";

// @ts-ignore
import { DataTable, DrawerPanel, Loader, SubHeader, IconButton } from "@roambee/client-styleguide";
// @ts-ignore
import { API, EventEmitter, generateQueryParams, getAuthUser } from "@roambee/client-utility";

import { Bee_Default_Filters, Bee_Table_Columns, Bee_Table_Filter_Operations, Bee_Table_Restricted_Columns } from "../configs/BeeTableConfig";
import { API_PATH, URL_PATH } from "../configs/BeeConfig";
import AllFilters from "./AllFilters";
import { Tooltip, Menu, MenuItem, ListItemIcon, ListItemText, Box } from "@mui/material";

let tableConfiguration: any = localStorage.getItem("bee_table");
if (tableConfiguration && tableConfiguration.length) {
	tableConfiguration = JSON.parse(tableConfiguration);
}

// filter string parser
const parseFilterString = (filterString) => {
	if (filterString && filterString.length) {
		return filterString.split(" and ").map((filter) => {
			// for device_type in 'BeeLockSolar', the below line returning value as "BeeLockSolar"
			const [id, operator, value] = filter.match(/(\w+)\s(\w+)\s'(.+)'/).slice(1);
			// if operator is in, then return array of values
			if (operator === "in") {
				return { id, value: value.split(",") };
			} else {
				return { id, value };
			}
		});
	} else {
		return null;
	}
};

const generateFilterString = (filters) => {
	return filters
		.filter((filter) => filter.value !== null && filter.value !== undefined && filter.value.length !== 0)
		.map((filter) => {
			if (filter.value.isCombination) {
				// if _combinedFilter[Object.keys(_combinedFilter)[0]].value has var_{value}_var then replace var_{value}_var with {value}
				return filter.value.value
					.map((_combinedFilter) => {
						const value = _combinedFilter[Object.keys(_combinedFilter)[0]].value;
						if (value.includes("var_")) {
							return `${Object.keys(_combinedFilter)[0]} ${_combinedFilter[Object.keys(_combinedFilter)[0]].matchMode} '${value.replace(/var_(\w+)_var/g, (_match, p1) => p1)}'`;
						}
						return `${Object.keys(_combinedFilter)[0]} ${_combinedFilter[Object.keys(_combinedFilter)[0]].matchMode} '${value}'`;
					})
					.join(" and ");
			} else {
				const operations = Bee_Table_Filter_Operations[filter.id || filter.field];
				if (!operations) return "";
				// Split operations by '|' to handle multiple operations
				return operations
					.split("|")
					.map((operation) => `${filter.id || filter.field} ${operation} '${filter.value}'`)
					.join(" or ");
			}
		})
		.filter((filter) => filter !== "")
		.join(" and ");
};

function BeeTable() {
	let urlFilters: any = {};
	const [searchParams, setSearchParams] = useSearchParams();
	if (searchParams && searchParams.get("filter")) {
		try {
			urlFilters = JSON.parse(decodeURIComponent(searchParams.get("filter") || ""));
			console.info(urlFilters, "Parsed URL Filters");
		} catch (e) {
			console.error("Failed to parse JSON:", e);
		}
	}

	// get user
	const user = getAuthUser();

	const [data, setData] = useState({ data: [], offset: 0, total: 0, size: 10 });
	const [loading, setLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState("");
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openRowId, setOpenRowId] = useState(null);

	// table
	const staticColumns = useMemo(() => Bee_Table_Columns, []);
	const [dynamicColumns, setDynamicColumns] = useState([]);
	const [columnFilters, setColumnFilters] = useState(parseFilterString(urlFilters?.filter) || parseFilterString(tableConfiguration?.filter) || [{ id: "active", value: "1" }]);
	const [columnFilterModes, setColumnsFilterModes] = useState();
	const [sorting, setSorting] = useState(urlFilters?.sort || tableConfiguration?.sort || []);
	const [pagination, setPagination] = useState({
		pageIndex: urlFilters?.offset / 10 || tableConfiguration?.offset / 10 || 0,
		pageSize: urlFilters?.size || tableConfiguration?.size || 10
	});
	const [columnPinning, setColumnPinning] = useState<any>(urlFilters?.columnPinning || tableConfiguration?.columnPinning || { left: [], right: ["actions"] });
	const [columnSizing, setColumnSizing] = useState<any>(urlFilters?.columnSizing || tableConfiguration?.columnSizing || {});
	const [columnOrdering, setColumnOrdering] = useState<any>(urlFilters?.columnOrdering || tableConfiguration?.columnOrdering || []);
	const [density, setDensity] = useState<any>(urlFilters?.density || tableConfiguration?.density || "comfortable");
	const [columnVisibility, setColumnVisibility] = useState<any>(urlFilters?.columnVisibility || tableConfiguration?.columnVisibility || {});

	// system filter
	const [systemFilters, setSystemFilters] = useState([]);
	const [selectedSystemFilter, setSelectedSystemFilter] = useState(null);
	const [selectedDefaultFilter, setSelectedDefaultFilter] = useState(null);
	const defaultFilters = useMemo(() => Bee_Default_Filters, []);

	const [headerButtonConfigs, setHeaderButtonConfigs] = useState([
		{
			tooltipText: "Copy Deep-Link",
			variant: "no-bg",
			size: "small",
			icon: <CopyLink />,
			onClick: () => handleCopyDeepLink()
		},
		{
			tooltipText: "Download Report",
			variant: "no-bg",
			size: "small",
			disabled: false,
			icon: <Download />,
			onClick: () => handleDownloadReport()
		}
	]);

	const handleDownloadReport = () => {
		const BASE_URL = process.env.CLIENT_HC10_API_URL;
		const downloadParams = {
			raw: {
				query: {
					bool: {
						must: [],
						must_not: []
					}
				}
			},
			fields: "all",
			filter: memoizedGenerateFilterString,
			"report-type": "xls"
		};
		const queryParams = generateQueryParams(downloadParams);
		window.open(BASE_URL + API_PATH.BEES + "?" + queryParams);
	};

	const handleCopyDeepLink = () => {
		navigator.clipboard.writeText(window.location.origin + window.location.pathname + `?filter=${localStorage.getItem("bee_table")}${localStorage.getItem("bee_activeTab") ? "&activeTab=" + localStorage.getItem("bee_activeTab") : ""}`);

		setHeaderButtonConfigs((prevConfigs) => {
			const updatedConfigs = [...prevConfigs];
			updatedConfigs.splice(0, 1, {
				tooltipText: "Link Copied!",
				variant: "no-bg",
				size: "small",
				icon: <Checkmark />,
				onClick: () => console.info("Link Copied!")
			});
			return updatedConfigs;
		});

		setTimeout(() => {
			setHeaderButtonConfigs((prevConfigs) => {
				const headerConfigs = [...prevConfigs];
				headerConfigs.splice(0, 1, {
					tooltipText: "Copy Deep-Link",
					variant: "no-bg",
					size: "small",
					icon: <CopyLink />,
					onClick: () => handleCopyDeepLink()
				});
				return headerConfigs;
			});
		}, 3500);
	};

	const actionItemConfigs = useMemo(
		() => [
			{
				label: "Bee Detail Page",
				disabled: false,
				icon: <Result />,
				onClick: (row) => onBeeDetails(row)
			},
			{
				label: "Messages",
				disabled: false,
				icon: <Chat />,
				onClick: (row) => onBeeMessage(row)
			},
			{
				label: "Deactivate",
				disabled: true,
				icon: <Close />,
				onClick: () => onBeeDeactivate()
			},
			{
				label: "Edit",
				disabled: false,
				icon: <Edit />,
				onClick: (row) => onBeeEdit(row)
			},
			{
				label: "Checkout",
				disabled: false,
				icon: <ShoppingCart />,
				onClick: () => onCheckout()
			},
			{
				label: "Configure Bee Zone",
				disabled: true,
				icon: <Settings />,
				onClick: () => onBeeConfigure()
			},
			{
				label: "Audit",
				disabled: false,
				icon: <View />,
				onClick: (row) => onAudit(row)
			}
		],
		[]
	);

	const handleFilterClick = useCallback(
		(tab) => {
			// Update columnFilters based on tab
			if (tab === "all") {
				// Remove filter with id: "active"
				setColumnFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== "active"));
			} else {
				// Check if there is already a filter with id: "active"
				const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "active");

				if (existingFilterIndex !== -1) {
					// Update existing filter with id: "active"
					const updatedColumnFilters = [...columnFilters];
					updatedColumnFilters[existingFilterIndex].value = tab === "active" ? "1" : "0";
					setColumnFilters(updatedColumnFilters);
				} else {
					// Add new filter with id: "active"
					setColumnFilters((prevFilters) => [...prevFilters, { id: "active", value: tab === "active" ? "1" : "0" }]);
				}
			}
		},
		[columnFilters]
	); // Add columnFilters as a dependency

	const addParamToUrl = useCallback(
		(key, value) => {
			searchParams.set(key, value);
			setSearchParams(searchParams);
		},
		[searchParams, setSearchParams]
	);

	const removeParamFromUrl = useCallback(
		(key) => {
			searchParams.delete(key);
			setSearchParams(searchParams);
		},
		[searchParams, setSearchParams]
	);

	const handleTabChange = useCallback(
		(key) => {
			if (key == "all") {
				setCurrentTab("all");
				removeParamFromUrl("activeTab");
				handleFilterClick("all");
				localStorage.removeItem("bee_activeTab");
			} else {
				setCurrentTab(key);
				addParamToUrl("activeTab", key);
				handleFilterClick(key);
				localStorage.setItem("bee_activeTab", key);
			}
		},
		[handleFilterClick, addParamToUrl, removeParamFromUrl]
	);

	const tabConfigs = useMemo(() => {
		return [
			{
				label: "All",
				active: currentTab === "all",
				onClick: () => {
					if (currentTab !== "all") {
						handleTabChange("all");
					}
				}
			},
			{
				label: "Active",
				active: currentTab === "active",
				onClick: () => {
					if (currentTab !== "active") {
						handleTabChange("active");
					}
				}
			},
			{
				label: "Inactive",
				active: currentTab === "inactive",
				onClick: () => {
					if (currentTab !== "inactive") {
						handleTabChange("inactive");
					}
				}
			}
		];
	}, [currentTab, handleTabChange]);

	const buttonConfigs = useMemo(() => [], []);

	const handleClick = (event, rowId) => {
		setAnchorEl(event.currentTarget);
		setOpenRowId(rowId);
	};

	const handleActionMenuClose = () => {
		setAnchorEl(null);
		setOpenRowId(null);
	};

	const handleBeeConfigure = () => {
		console.info("Bee Configure");
	};

	const actionColumn = useMemo(() => {
		return {
			id: "actions",
			header: "Actions",
			Header: (
				<Tooltip title="Actions">
					<div>
						<IconButton ariaLabel="Actions" icon={<Settings />} disabled={true} size="small" variant="no-bg" onClick={() => handleBeeConfigure()} />
					</div>
				</Tooltip>
			),
			size: 60,
			columnDefType: "display",
			enablePinning: false,
			Cell: ({ row }) => {
				const isOpen = openRowId === row.id;
				return (
					<>
						<Box>
							<IconButton ariaLabel="Row actions" icon={<OverflowMenuVertical />} size="small" variant="no-bg" aria-controls={isOpen ? "actions-menu" : undefined} aria-haspopup="true" aria-expanded={isOpen ? "true" : undefined} onClick={(e) => handleClick(e, row.id)} />
						</Box>
						<Menu anchorEl={anchorEl} id="datable-Menu" open={isOpen} onClose={handleActionMenuClose} onClick={handleActionMenuClose} transformOrigin={{ horizontal: "right", vertical: "top" }} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
							{actionItemConfigs.map((actionMenuItem) => (
								<MenuItem key={actionMenuItem.label} onClick={() => actionMenuItem.onClick(row)} disabled={actionMenuItem.disabled}>
									<ListItemIcon>{actionMenuItem.icon}</ListItemIcon>
									<ListItemText primary={actionMenuItem.label} />
								</MenuItem>
							))}
						</Menu>
					</>
				);
			}
		};
	}, [anchorEl, openRowId, actionItemConfigs]);

	const combinedColumns = useMemo(() => {
		if (user && user.role_names) {
			const isSuperAdmin = user.role_names.includes("super-admin") || user.role_names.includes("roambee-admin");

			const filteredStaticColumns = isSuperAdmin ? staticColumns : staticColumns.filter((column) => !Bee_Table_Restricted_Columns.includes(column.accessorKey));

			return [...filteredStaticColumns, ...dynamicColumns, actionColumn];
		}

		return [...staticColumns, ...dynamicColumns, actionColumn];
	}, [user, staticColumns, dynamicColumns, actionColumn]);

	const handleColumnPinningChange = (newColumnPinning) => {
		// Check if newColumnPinning is a function, and if so, call it to get the new state
		const updatedColumnPinning =
			typeof newColumnPinning === "function"
				? newColumnPinning(columnPinning) // call the function with current state
				: newColumnPinning;

		// Ensure the right array exists and 'actions' is appended to the end
		const right = updatedColumnPinning?.right ?? [];
		const updatedRight = right.filter((col) => col !== "actions"); // Remove 'actions' temporarily

		setColumnPinning({
			...updatedColumnPinning,
			right: [...updatedRight, "actions"] // Ensure 'actions' is always at the end
		});
	};

	const memoizedGenerateFilterString = useMemo(() => generateFilterString(columnFilters), [columnFilters]);

	const throttle = (func, limit) => {
		let lastFunc;
		let lastRan;
		return (...args) => {
			if (!lastRan) {
				func(...args);
				lastRan = Date.now();
			} else {
				clearTimeout(lastFunc);
				lastFunc = setTimeout(() => {
					if (Date.now() - lastRan >= limit) {
						func(...args);
						lastRan = Date.now();
					}
				}, limit - (Date.now() - lastRan));
			}
		};
	};

	useEffect(() => {
		const saveTableConfig = throttle(() => {
			// Fetch the previous state from local storage
			const getsavedTableconfig = JSON.parse(localStorage.getItem("bee_table")) || {};
			const updatedTableConfig = {
				...getsavedTableconfig,
				columnPinning,
				columnSizing,
				columnOrdering,
				density,
				columnVisibility
			};
			// Save the updated state to local storage
			localStorage.setItem("bee_table", JSON.stringify(updatedTableConfig));
		}, 1000); // Throttle to 1 second

		saveTableConfig();
	}, [columnPinning, columnSizing, columnOrdering, density, columnVisibility]);

	const fetchData = useCallback(() => {
		setLoading(true);
		const activeTab = localStorage.getItem("bee_activeTab") || null;
		const urlActiveTab = searchParams.get("activeTab") || null;
		if (activeTab) {
			if (activeTab == "active") {
				setCurrentTab("active");
				addParamToUrl("activeTab", "active");
			} else if (activeTab == "inactive") {
				setCurrentTab("inactive");
				addParamToUrl("activeTab", "inactive");
			}
		} else if (urlActiveTab) {
			if (urlActiveTab == "active") {
				setCurrentTab("active");
				localStorage.setItem("bee_activeTab", "active");
			} else if (urlActiveTab == "inactive") {
				setCurrentTab("inactive");
				localStorage.setItem("bee_activeTab", "inactive");
			}
		} else {
			if (currentTab !== "all") {
				handleTabChange("active");
			}
		}
		const params = {
			offset: pagination.pageIndex * 10 || 0,
			size: pagination.pageSize || 10,
			fields: "all",
			filter: memoizedGenerateFilterString,
			sort: sorting
		};
		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.BEES}?${queryString}`)
			.then((result) => {
				setData(result);
				if (result.total > 10000) {
					setHeaderButtonConfigs((prevConfigs) => {
						const updatedConfigs = [...prevConfigs];
						updatedConfigs.splice(1, 1, {
							tooltipText: "Bee list size is more than 10,000!\nPlease apply some filters to reduce the size and then you can download report.",
							variant: "no-bg",
							size: "small",
							disabled: true,
							icon: <Download />,
							onClick: () => console.info("Bee list size is more than 10,000!\nPlease apply some filters to reduce the size and then you can download report.")
						});
						return updatedConfigs;
					});
				} else {
					setHeaderButtonConfigs((prevConfigs) => {
						const updatedConfigs = [...prevConfigs];
						updatedConfigs.splice(1, 1, {
							tooltipText: "Download Report",
							variant: "no-bg",
							size: "small",
							disabled: false,
							icon: <Download />,
							onClick: () => handleDownloadReport()
						});
						return updatedConfigs;
					});
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
				setLoading(false);
			});

		localStorage.setItem("bee_table", JSON.stringify(params));
	}, [memoizedGenerateFilterString, sorting, pagination]);

	// Debounce fetchData to limit API calls
	const debouncedFetchData = useMemo(() => {
		return throttle(fetchData, 300); // Adjust the delay as needed
	}, [fetchData]);

	useEffect(() => {
		debouncedFetchData();
	}, [debouncedFetchData]);

	useEffect(() => {
		const params = {
			rbql: {
				filters: [
					{ name: "account_id", value: user?.account?.id },
					{ name: "type", value: "bee" },
					{ name: "active", value: 1 }
				],
				other_options: { return_all: true }
			}
		};
		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.AUTO_CRUD_CUSTOM_FIELDS}?${queryString}`)
			.then((result) => {
				const apiColumns = result.data.map((item) => ({
					accessorKey: item.es_identifier,
					Header: (
						<Tooltip title={item.fieldname}>
							<div>{item.fieldname}</div>
						</Tooltip>
					),
					header: item.placeholder,
					isSortable: true,
					isSearchable: true,
					filterVariant: item.datatype === "select" ? "select" : undefined,
					filterSelectOptions: item.values ? JSON.parse(item.values).map((val) => val.value) : undefined
				}));
				setDynamicColumns(apiColumns);
				setLoading(false);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
				setLoading(false);
			});
	}, [user]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		const queryString = generateQueryParams({ entity: "BEE" });
		API("GET", `${API_PATH.FILTER}?${queryString}`)
			.then((result) => {
				const _systemFilters = result.data.map((f) => {
					return { ...f, label: f.name };
				});
				setSystemFilters(_systemFilters);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
			});
	}, []);

	useEffect(() => {
		const savedDefaultFilter = localStorage.getItem("bee_default_filter");
		if (savedDefaultFilter) {
			setSelectedDefaultFilter(JSON.parse(savedDefaultFilter));
		}
	}, [defaultFilters]);

	useEffect(() => {
		const savedSystemFilter = localStorage.getItem("bee_system_filter");
		if (savedSystemFilter) {
			setSelectedSystemFilter(JSON.parse(savedSystemFilter));
		}
	}, [defaultFilters]);

	const handleAllFilters = () => {
		setOpen(true);
	};

	const handleSaveFilters = (selectedFilters) => {
		console.info("Save Filters:", selectedFilters);
		// selectedFilters is array of [{accessorKey: "abc"}]
		// columnFilters is array of [{id: "abc", value: "def"}]
		// find columnFilters where columnFilter.id is selectedFilter.accessorKey
		const appliedFilters = [];
		selectedFilters.forEach((filter) => {
			// find filter in columnFilters where columnFilter.id is filter.accessorKey
			const columnFilter = columnFilters.find((c) => c.id === filter.accessorKey);
			if (columnFilter) {
				// decide value based on filterVariant
				if (filter.filterVariant === "select") {
					appliedFilters.push({ ...filter, value: { label: columnFilter.value, value: columnFilter.value } });
				} else if (filter.filterVariant === "multi-select") {
					appliedFilters.push({ ...filter, value: columnFilter.value.map((v) => ({ label: v, value: v })) });
				} else {
					appliedFilters.push({ ...filter, value: columnFilter.value });
				}
			}
		});
		console.info("Applied Filters:", appliedFilters);

		combinedColumns.forEach((column) => {
			const appliedFilter = appliedFilters.find((f) => f.accessorKey === column.accessorKey);
			if (appliedFilter) {
				column.value = appliedFilter.value;
			}
		});

		setOpen(true);
	};

	const handleSystemFilters = (event, selectedFilter) => {
		console.info("Selected System Filter:", selectedFilter);
		if (selectedFilter && selectedFilter.filterJson && selectedFilter.filterJson.filters) {
			setSelectedSystemFilter(selectedFilter);

			// apply filter to the grid
			// get filter json and append into columnFilters
			const filters = selectedFilter.filterJson.filters;
			// Merge filters without duplicates
			const desiredFilters = [
				{ id: "parent_shipment", value: "false" },
				{ id: "active", value: "1" }
			];

			// Check if any filter has field equal to "status"
			const hasStatusField = filters.some((filter) => filter.field === "status");

			// Create mergedFilters with existing filters and desired filters if not present
			const mergedFilters = [
				...filters,
				...desiredFilters.filter(
					(desired) => !filters.some((filter) => filter.id === desired.id) && !(desired.id === "active" && hasStatusField) // Check for status field
				)
			];

			setColumnFilters(mergedFilters);

			// store selectedFilter id into localStorage
			selectedFilter = {
				...selectedFilter,
				label: selectedFilter.name
			};
			localStorage.setItem("bee_system_filter", JSON.stringify(selectedFilter));
		} else {
			// remove selectedSystemFilter from columnFilters
			setColumnFilters((prevFilters) => prevFilters.filter((filter) => !selectedSystemFilter.filterJson.filters.some((sysFilter) => sysFilter.field === filter.id)));
			setSelectedSystemFilter(null);
			// remove selectedFilter id from localStorage
			localStorage.removeItem("bee_system_filter");
		}
	};

	const handleDefaultFilters = (event, selectedFilter) => {
		console.info("Default Filter:", selectedFilter);

		if (selectedFilter) {
			setSelectedDefaultFilter(selectedFilter);
			// apply filter to the grid
			// get filter json and append into columnFilters
			const filters = [selectedFilter];
			// Merge filters without duplicates
			const mergedFilters = [...filters.filter((filter) => !columnFilters.some((colFilter) => colFilter.id === filter.field))];

			// Format mergedFilters as needed
			const formattedFilters = mergedFilters.map((filter) => ({
				id: filter.field || filter.id,
				value: filter.value
			}));

			setColumnFilters(formattedFilters);

			// store selectedFilter id into localStorage
			localStorage.setItem("bee_default_filter", JSON.stringify(selectedFilter));
		} else {
			setSelectedDefaultFilter(null);
			localStorage.removeItem("bee_default_filter");
		}
	};

	const onBeeDetails = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.BEE_DETAILS + row.original.bee_uuid);
	};

	const onBeeMessage = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.BEE_DETAILS + row.original.bee_uuid + URL_PATH.MESSAGES);
	};

	const onBeeDeactivate = () => {
		return;
	};

	const onBeeEdit = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.BEE_EDIT + row.original.imei);
	};

	const onCheckout = () => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.CHECKOUT);
	};

	const onBeeConfigure = () => {
		return;
	};

	const onAudit = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.AUDIT + "?type=DEVICE" + "&uuid=" + row.original.imei + "&startDate=" + row.original.created_date + "&endDate=" + row.original.last_known_timestamp + "&redirected=true");
	};

	const handleFilterApplied = (filter) => {
		console.info("Filter applied:", filter);
		// add filter into systemFilters when add filter is an object
		setSystemFilters((prevFilters) => [...prevFilters, { ...filter, label: filter.name }]);

		handleSystemFilters(null, { ...filter, label: filter.name });
		setOpen(false);
	};

	const handleCancelFilter = () => {
		setOpen(false);
	};

	return (
		<section id="bee-table">
			{loading && <Loader />}
			<SubHeader tabConfigs={tabConfigs} buttonConfigs={buttonConfigs} currentTab={currentTab} onTab={setCurrentTab} />
			<DataTable
				title="Bees"
				columns={combinedColumns}
				data={data?.data || []}
				isLoading={loading}
				columnFilters={columnFilters}
				onColumnFiltersChange={setColumnFilters}
				columnFilterFns={columnFilterModes}
				onColumnFilterFnsChange={setColumnsFilterModes}
				sorting={sorting}
				onSortingChange={setSorting}
				pagination={pagination}
				onPaginationChange={setPagination}
				rowCount={data.total}
				headerButtonConfigs={headerButtonConfigs}
				onColumnSizingChange={setColumnSizing}
				columnSizing={columnSizing}
				onColumnOrderChange={setColumnOrdering}
				columnOrder={columnOrdering}
				density={density}
				onDensityChange={setDensity}
				onColumnVisibilityChange={setColumnVisibility}
				columnVisibility={columnVisibility}
				columnPinning={columnPinning}
				onColumnPinningChange={handleColumnPinningChange}
				systemFilters={systemFilters}
				onSystemFilters={handleSystemFilters}
				selectedSystemFilter={selectedSystemFilter}
				defaultFilters={defaultFilters}
				selectedDefaultFilter={selectedDefaultFilter}
				onAllFilters={handleAllFilters}
				onSaveFilters={handleSaveFilters}
				onDefaultFilters={handleDefaultFilters}
				setSelectedSystemFilter={setSelectedSystemFilter}
			/>
			{open && (
				<DrawerPanel open={open} setOpen={setOpen} title="Filter">
					<AllFilters allowAI={true} systemFilters={systemFilters} selectedFilter={selectedSystemFilter} filters={combinedColumns.filter((c) => c.isSearchable)} onFilterApplied={handleFilterApplied} onCancelFilter={handleCancelFilter} />
				</DrawerPanel>
			)}
		</section>
	);
}
export default BeeTable;
