/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { formatDateTime, getTemperatureConversion } from "@roambee/client-utility";
// @ts-ignore
import { Tag, AppThemes, withTooltip } from "@roambee/client-styleguide";
// @ts-ignore
import { Tooltip } from "@mui/material";

const getCarriers = () => {
	try {
		// TODO : get this from rb-of
		const carriers = ["Aeris-Global", "Onomondo", "Aeris-AT&T", "Airtel", "floLIVE", "Maxis", "Orange", "Other", "Sprint", "Telefonica", "Telenor", "Telkomsel", "Tmobile", "Vodafone"];
		return carriers;
	} catch (e) {
		return [];
	}
};

const getDevices = () => {
	try {
		// TODO : get this from rb-of
		const devices = ["Android", "BLE", "BLESensor", "BLELocation", "BeeAware", "BeeAware-GSM", "BeeAware-LTE-XL", "BeeLock", "BeeRouter", "BeeSense", "BeeSenseAir", "GL200", "GL300", "GL500", "GL53MG", "IEThings", "iOS", "MOOG3", "MOOG4", "Myriota", "OBD2", "OCR_SONAR", "QTS110", "SENSOR", "SFX-ALPS", "Wiliot", "TOPFLY_SOLAR", "BeeSenseSolar500", "BeeTrac300", "BeeTracSolar400", "GL53", "BeeSenseFlex", "BeeLockSolar", "BeeTracFit", "BeeAssetFit"];
		return devices;
	} catch (e) {
		return [];
	}
};

const getCommunications = () => {
	return ["OK", "NOCOMM", "NONTWK"];
};

const getBleState = () => {
	return ["true", "false"];
};

const getAMOState = () => {
	return ["true", "false"];
};

const getBatteryTypes = () => {
	// TODO : get this from rb_of
	return ["CR123", "Li-ion", "NLB", "CR2032"];
};

const getBatteryStatus = () => {
	// TODO : get this from rb_of
	return ["Normal", "Charging", "Low", "Drained"];
};

const Status_Colors = {
	Normal: {
		bgColor: AppThemes.light.colors.supportSuccess50,
		color: AppThemes.light.colors.supportSuccess400
	},
	Charging: {
		bgColor: AppThemes.light.colors.purpleSunset50,
		color: AppThemes.light.colors.purpleSunset400
	},
	Low: {
		bgColor: AppThemes.light.colors.supportWarning50,
		color: AppThemes.light.colors.supportWarning400
	},
	Drained: {
		bgColor: AppThemes.light.colors.supportError50,
		color: AppThemes.light.colors.supportError400
	}
};

const Communication_Colors = {
	OK: {
		bgColor: AppThemes.light.colors.supportSuccess50,
		color: AppThemes.light.colors.supportSuccess400
	},
	NOCOMM: {
		bgColor: AppThemes.light.colors.supportWarning50,
		color: AppThemes.light.colors.supportWarning400
	},
	NONTWK: {
		bgColor: AppThemes.light.colors.supportError50,
		color: AppThemes.light.colors.supportError400
	}
};

const Bee_Table_Restricted_Columns = ["sim_number", "last_clear_timestamp", "account_name", "imsi", "imei", "bee_version", "firmware", "listener_hostname", "last_message_protocol", "communication", "battery_type", "last_known_timestamp", "dev_src"];

const Bee_Table_Columns = [
	{
		accessorKey: "account_name",
		header: "Account Name",
		Header: (
			<Tooltip title="Account Name" placement="top">
				<div>Account Name</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "bee_name",
		header: "Bee Name",
		Header: (
			<Tooltip title="Bee Name" placement="top">
				<div>Bee Name</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: true,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "bee_number",
		header: "Bee Number",
		Header: (
			<Tooltip title="Bee Number" placement="top">
				<div>Bee Number</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "device_type",
		header: "Type",
		isSortable: true,
		isSearchable: true,
		filterVariant: "multi-select",
		filterSelectOptions: getDevices(),
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "shipment_name",
		header: "Status",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => {
			const renderedCellValue = cell.getValue();
			const shipmentUuid = cell.row.original.shipment_uuid; // Accessing row data correctly

			return shipmentUuid ? (
				<a href={`${process.env.CLIENT_HC10_URL}/shipments/details/${shipmentUuid}`} target="_blank" rel="noopener noreferrer">
					{renderedCellValue}
				</a>
			) : (
				<Tag label="Unallocated" backgroundColor={AppThemes.light.colors.purpleSunset50} color={AppThemes.light.colors.purpleSunset400} />
			);
		})
	},
	{
		accessorKey: "created_date",
		header: "Added",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a")),
		enableEditing: false
	},
	{
		accessorKey: "last_known_location",
		header: "Last Known Location",
		Header: (
			<Tooltip title="Last Known Location" placement="top">
				<div>Last Known Location</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "imei",
		header: "IMEI",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "mobile_number",
		header: "Mobile Number",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "sim_number",
		header: "SIM Number",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "carrier",
		header: "Carrier",
		isSortable: true,
		isSearchable: true,
		filterVariant: "multi-select",
		filterSelectOptions: getCarriers(),
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "bee_version",
		header: "Bee Version",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "firmware",
		header: "Firmware",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "last_message_timestamp",
		header: "Last Message",
		Header: (
			<Tooltip title="Last Message" placement="top">
				<div>Last Message</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a")),
		enableEditing: false
	},
	{
		accessorKey: "listener_hostname",
		header: "Last Message Server",
		Header: (
			<Tooltip title="Last Message Server" placement="top">
				<div>Last Message Server</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: false,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => {
			const bState = cell.row.original.state ? JSON.parse(cell.row.original.state) : {};
			return bState?.last_msg?.msg?.listener_hostname || "-";
		})
	},
	{
		accessorKey: "last_message_protocol",
		header: "Protocol",
		Header: (
			<Tooltip title="Protocol" placement="top">
				<div>Protocol</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "battery_remaining_days",
		header: "Battery",
		Header: (
			<Tooltip title="Battery" placement="top">
				<div>Battery</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => {
			const batt = cell.row.original && cell.row.original.battery_remaining_days && cell.row.original.battery_remaining_days !== 0 ? Math.round(cell.row.original.battery_remaining_days) : 0;
			const battPercent = cell.row.original.battery_remaining_percentage ? cell.row.original.battery_remaining_percentage : 0;
			return `${batt} days (${battPercent}%)`;
		})
	},
	{
		accessorKey: "bee_lock",
		header: "BeeLock",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "device_mode",
		header: "Mode",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "bleEnable",
		Header: (
			<Tooltip title="BLE Enabled" placement="top">
				<div>BLE Enabled</div>
			</Tooltip>
		),
		header: "BLE Enabled",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "select",
		filterSelectOptions: getBleState(),
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "amo_state",
		header: "AMO State",
		Header: (
			<Tooltip title="AMO State" placement="top">
				<div>AMO State</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "select",
		filterSelectOptions: getAMOState(),
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "battery_state",
		header: "Battery State",
		Header: (
			<Tooltip title="Battery State" placement="top">
				<div>Battery State</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "multi-select",
		filterSelectOptions: getBatteryStatus(),
		Cell: withTooltip(({ cell }) => {
			const renderedCellValue = cell.getValue();
			if (!renderedCellValue) {
				return "-";
			}
			const { bgColor, color } = Status_Colors[renderedCellValue] || {};
			return <Tag label={renderedCellValue} backgroundColor={bgColor} color={color} />;
		})
	},
	{
		accessorKey: "battery_remaining_percentage",
		header: "Battery Remaining Percentage",
		Header: (
			<Tooltip title="Battery Remaining Percentage" placement="top">
				<div>Battery Remaining Percentage</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: ({ cell }) => (cell.getValue() ? `${cell.getValue()}%` : "-")
	},
	{
		accessorKey: "battery_type",
		header: "Battery Type",
		Header: (
			<Tooltip title="Battery Type" placement="top">
				<div>Battery Type</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "multi-select",
		filterSelectOptions: getBatteryTypes(),
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "communication",
		header: "Communication",
		isSortable: true,
		isSearchable: true,
		filterVariant: "multi-select",
		enableEditing: false,
		filterSelectOptions: getCommunications(),
		Cell: withTooltip(({ cell }) => {
			const renderedCellValue = cell.getValue();
			if (!renderedCellValue) {
				return "-";
			}
			const { bgColor, color } = Communication_Colors[renderedCellValue] || {};
			return <Tag label={renderedCellValue} backgroundColor={bgColor} color={color} />;
		})
	},
	{
		accessorKey: "temp",
		header: "Temperature",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	},
	{
		accessorKey: "pressure",
		header: "Pressure",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "humidity",
		header: "Humidity",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "ambient",
		header: "Ambient",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "tiltshock",
		header: "Tilt",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "altitude",
		header: "Altitude",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "tamper",
		header: "Tamper",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "shock",
		header: "Shock",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "last_known_timestamp",
		header: "Last Known Time",
		Header: (
			<Tooltip title="Last Known Time" placement="top">
				<div>Last Known Time</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a")),
		enableEditing: false
	},
	{
		accessorKey: "hub_name",
		header: "Hub",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "ping_frequency",
		header: "PRF",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "beezone_label",
		header: "BeeZone",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "VIN_number",
		header: "VIN",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "control_window",
		Header: (
			<Tooltip title="Control Window" placement="top">
				<div>Control Window</div>
			</Tooltip>
		),
		header: "Control Window",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "offset",
		header: "Offset",
		isSortable: true,
		isSearchable: false,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "last_clear_timestamp",
		header: "Last Clear Time",
		Header: (
			<Tooltip title="Last clear time" placement="top">
				<div>Last clear time</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a")),
		enableEditing: false
	},
	{
		accessorKey: "imsi",
		header: "IMSI",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "pharma_config",
		header: "Pharma Config",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	},
	{
		accessorKey: "router_last_reset_timestamp",
		header: "Router last reset time",
		Header: (
			<Tooltip title="Router last reset time" placement="top">
				<div>Router last reset time</div>
			</Tooltip>
		),
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a")),
		enableEditing: false
	},
	{
		accessorKey: "dev_src",
		header: "Dev Src",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => cell.getValue() || "-")
	}
];

const Bee_Table_Filter_Operations = {
	bee_name: "has",
	bee_number: "has",
	imei: "has",
	account_name: "eq|has",
	mobile_number: "has",
	sim_number: "has",
	carrier: "eq",
	bee_version: "has",
	firmware: "has",
	last_message_timestamp: "has",
	listener_hostname: "has",
	last_message_protocol: "has",
	battery_remaining_days: "eq",
	battery_remaining_percentage: "eq",
	bee_lock: "has",
	device_mode: "has",
	bleEnable: "eq",
	amo_state: "eq",
	battery_state: "has",
	battery_type: "eq",
	communication: "eq",
	temp: "eq",
	pressure: "eq",
	humidity: "eq",
	ambient: "eq",
	tiltshock: "eq",
	altitude: "eq",
	tamper: "has",
	shock: "eq",
	shipment_name: "has",
	last_known_location: "has",
	last_known_timestamp: "has",
	hub_name: "has",
	ping_frequency: "eq",
	beezone_label: "has",
	VIN_number: "has",
	control_window: "has",
	offset: "has",
	last_clear_timestamp: "has",
	imsi: "has",
	pharma_config: "has",
	router_last_reset_timestamp: "has",
	dev_src: "has",
	device_type: "in",
	active: "eq"
	// Add more column mappings here
};

const Bee_Default_Filters = [
	{
		label: "Status",
		options: [
			{
				label: "Ready for Shipment",
				value: {
					isCombination: true,
					value: [
						{
							asset_name: { value: "unallocated", matchMode: "eq" }
						},
						{
							shipment_name: { value: "unallocated", matchMode: "eq" }
						},
						{
							communication: { value: "OK", matchMode: "eq" }
						},
						{
							battery_state: { value: "Normal", matchMode: "eq" }
						}
					]
				}
			},
			{
				label: "On Shipment",
				value: {
					isCombination: true,
					value: [{ shipment_name: { value: "unallocated", matchMode: "ne" } }, { shipment_name: { value: "var_true_var", matchMode: "exists" } }]
				}
			},
			{
				label: "On Asset",
				value: {
					isCombination: true,
					value: [{ asset_name: { value: "unallocated", matchMode: "ne" } }, { asset_uuid: { value: "var_true_var", matchMode: "exists" } }]
				}
			},
			{
				label: "Unallocated",
				value: {
					isCombination: true,
					value: [{ shipment_name: { value: "unallocated", matchMode: "eq" } }, { shipment_name: { value: "var_true_var", matchMode: "exists" } }]
				}
			}
		]
	}
];

export { Bee_Table_Columns, Bee_Table_Filter_Operations, Bee_Table_Restricted_Columns, Bee_Default_Filters };
