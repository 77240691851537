/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState, useMemo } from "react";
import styled from "@emotion/styled";
import { MagicWandFilled } from "@carbon/icons-react";
// @ts-ignore
import { AppThemes, Button, SelectSearch, InputText, SelectCheckboxesSearch } from "@roambee/client-styleguide";
// @ts-ignore
import { API } from "@roambee/client-utility";
import { API_PATH } from "../configs/BeeConfig";

const AllFilterWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
const FilterHeaderWrapper = styled.div`
	height: 7.5rem;
	padding-block: 1.5rem;
`;
const FiltersWrapper = styled.div`
	flex-grow: 1; /* This allows the content to take up the remaining space */
	overflow-y: auto; /* Makes the middle content scrollable */
	height: calc(100vh - 19.5rem);
	gap: 0.75rem;
	display: grid;
	background-color: ${AppThemes.light.colors.neutral100};
	border-radius: 8px;
	padding: 1rem;
`;
const FilterFooterWrapper = styled.div`
	height: 4rem;
	padding-block-end: 1.5rem;
	padding-block-start: 1rem;
`;
const ButtonGroup = styled.div`
	display: flex;
	gap: 1rem;
	width: 100%;
	margin-block-start: 1rem;
	& > button {
		width: 50%; /* Each button takes 50% of the ButtonGroup */
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
const SelectFilter = styled(SelectSearch)`
	width: 100%;
`;
const MultiSelectFilter = styled(SelectCheckboxesSearch)`
	width: 100%;
`;

const AIButton = styled(Button)`
	border: 1px solid ${AppThemes.light.colors.oceanicBlue400};
	color: ${AppThemes.light.colors.oceanicBlue400};
	margin-block-end: 0.75rem;
	&:hover {
		border-color: ${AppThemes.light.colors.oceanicBlue400};
		background-color: ${AppThemes.light.colors.oceanicBlue50};
	}
`;

const switchToAIFilter = () => {
	console.info("Switch to AI filter");
};

// filter variant : select, multi-select, calendar
// calendar filter variant is used for date range filter
// multi-select filter variant is used for multiple values selection
// select filter variant is used for single value selection

// for filter variant select and multi-select, the value is an object with label and value
// for other filter variant, the value is a string
function AllFilters({ allowAI, systemFilters, selectedFilter, filters, onFilterApplied, onCancelFilter }) {
	const [selectedSystemFilter, setSelectedSystemFilter] = useState(null);
	const [filterName, setFilterName] = useState("");
	const [finalFilters, setFinalFilters] = useState(filters.filter((f) => f.value !== undefined).map((f) => ({ field: f.accessorKey, value: f.value })));
	const [isDirty, setIsDirty] = useState(false);

	const [filtersWithCallback, setFiltersWithCallback] = useState(() => filters.map((filter) => filter));

	useEffect(() => {
		if (selectedFilter) {
			setSelectedSystemFilter(selectedFilter);
			setFilterName(selectedFilter.name);

			// find filter in filtersWithCallback and set value
			selectedFilter.filterJson.filters.forEach((filter) => {
				const filterIndex = filtersWithCallback.findIndex((f) => f.accessorKey === filter.field);
				if (filterIndex > -1) {
					// if filter.filterVariant is select, set value as {label: value, value: value}
					// if filter.filterVariant is multi-select, set value as [{label: value, value: value}]
					if (filtersWithCallback[filterIndex].filterVariant === "select") {
						filtersWithCallback[filterIndex].value = { label: filter.value[0], value: filter.value[0] };
					} else if (filtersWithCallback[filterIndex].filterVariant === "multi-select") {
						filtersWithCallback[filterIndex].value = filter.value.map((v) => ({ label: v, value: v }));
					} else {
						filtersWithCallback[filterIndex].value = filter.value;
					}
				}
			});
			setFiltersWithCallback([...filtersWithCallback]);

			// filter out filtersWithCallback where value key is not there
			setFinalFilters(filtersWithCallback.filter((f) => f.value !== undefined).map((f) => ({ field: f.accessorKey, value: f.value })));
		}
	}, [selectedFilter]);

	// memoize AIButtonComponent
	const AIButtonComponent = useMemo(
		() =>
			({ switchToAIFilter }) => {
				return <AIButton icon={<MagicWandFilled />} label="Try AI filters" size="small" onClick={switchToAIFilter} variant="outlined" />;
			},
		[]
	);

	// Memoized Filter Input renderer
	const renderFilterInput = (filter) => {
		const handleValueChange = (event, value) => {
			console.info(`Filter ${filter.accessorKey} changed:`, value);

			// update filter value
			filter.value = value;

			// handle null value
			if (!value) {
				// remove filter from finalFilters
				setFinalFilters(finalFilters.filter((f) => f.field !== filter.accessorKey));
				return;
			}
			// if value is array || value i string, then check length > 0
			// if value is string, then check value.value is not empty
			if (((Array.isArray(value) || typeof value === "string") && value.length > 0) || (typeof value === "object" && value.value)) {
				// Create or update the filter object
				const newFilter = {
					field: filter.accessorKey,
					value: filter.filterVariant === "select" ? [value.value] : value
				};

				if (filter.filterVariant === "multi-select") {
					newFilter.value = newFilter.value.map((v) => v.value);
				}

				// finalFilters are empty at this point, why?
				const existingFilterIndex = finalFilters.findIndex((f) => f.field === filter.accessorKey);
				// don't update value, if not changed
				if (existingFilterIndex > -1) {
					// Update existing filter value
					finalFilters[existingFilterIndex].value = newFilter.value;
					setFinalFilters([...finalFilters]);
				} else {
					// Create new filter
					finalFilters.push(newFilter);
					setFinalFilters([...finalFilters]);
				}
			} else {
				// remove filter from finalFilters
				setFinalFilters(finalFilters.filter((f) => f.field !== filter.accessorKey));
			}

			console.info("Final filters", finalFilters);

			setIsDirty(true);
		};

		if (filter.filterVariant === "select") {
			const filterOptions = filter.filterSelectOptions.map((f) => ({ label: f, value: f }));
			return <SelectFilter id={filter.accessorKey} label={filter.header} placeholder={`Search ${filter.header}`} options={filterOptions} disabled={false} value={filter.value} handleValueChange={handleValueChange} />;
		} else if (filter.filterVariant === "multi-select") {
			const filterOptions = filter.filterSelectOptions.map((f) => ({ label: f, value: f }));
			return <MultiSelectFilter id={filter.accessorKey} label={filter.header} placeholder={`Search ${filter.header}`} options={filterOptions} disabled={false} value={filter.value || []} onChange={handleValueChange} />;
		} else {
			return <InputText id={filter.accessorKey} name={filter.accessorKey} label={filter.header} defaultValue={filter.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange(e, e.target.value)} placeholder={`Search ${filter.header}`} disabled={false} error={false} help={false} debounceTime={300} size="small" />;
		}
	};

	const handleSystemFilterSelection = (_event, selectedFilter) => {
		console.info("Saved filter", selectedFilter);
		setSelectedSystemFilter(selectedFilter);

		if (selectedFilter && selectedFilter.filterJson) {
			// set finalFilters to selectedFilter.filterJson.filters
			setFinalFilters(selectedFilter.filterJson.filters);
			setFilterName(selectedFilter.name);

			// find filter in filtersWithCallback and set value
			selectedFilter.filterJson.filters.forEach((filter) => {
				const filterIndex = filtersWithCallback.findIndex((f) => f.accessorKey === filter.field);
				if (filterIndex > -1) {
					// if filter.filterVariant is select, set value as {label: value, value: value}
					// if filter.filterVariant is multi-select, set value as [{label: value, value: value}]
					if (filtersWithCallback[filterIndex].filterVariant === "select") {
						filtersWithCallback[filterIndex].value = { label: filter.value[0], value: filter.value[0] };
					} else if (filtersWithCallback[filterIndex].filterVariant === "multi-select") {
						filtersWithCallback[filterIndex].value = filter.value.map((v) => ({ label: v, value: v }));
					} else {
						filtersWithCallback[filterIndex].value = filter.value;
					}
				}
			});
			setFiltersWithCallback([...filtersWithCallback]);
		} else {
			// reset FiltersWithCallback
			setFiltersWithCallback(filters.map((f) => ({ ...f, value: undefined })));
			setFinalFilters([]);
			setFilterName("");
		}
	};

	const handleSaveFilter = () => {
		let restify = "";
		const finalFilter = finalFilters.map((filter) => {
			if (Array.isArray(filter.value)) {
				return { ...filter, value: filter.value.map((v) => v.value || v) };
			}
			if (typeof filter.value === "object") {
				return { ...filter, value: [filter.value.value] || filter.value };
			}
			return filter;
		});
		finalFilter.forEach((filter) => {
			// add ' and ' before each filter, but not for the first filter
			if (restify !== "") {
				restify += " and ";
			}
			// TODO : can we use in match mode ?
			if (Array.isArray(filter.value)) {
				filter.value.forEach((value, index) => {
					if (index !== 0) {
						restify += " or ";
					}
					restify += `${filter.field} eq '${value}'`;
				});
			} else {
				restify += `${filter.field} has '${filter.value}'`;
			}
		});
		console.info("Restify", restify);
		const params = {
			entity: "BEE",
			filterJson: JSON.stringify({ filters: finalFilter, restify: restify }),
			type: 0,
			name: filterName
		};
		console.info("Params", params);

		// no need to call API, if there is no change in selectedSystemFilter's object
		// if selectedSystemFilter is null, then it is a new filter, so we need to call API
		if (selectedSystemFilter) {
			if (JSON.stringify(selectedSystemFilter.filterJson) === JSON.stringify({ filters: finalFilter, restify: restify })) {
				return;
			}
		}

		// if selectedSystemFilter, then use PUT, else use POST
		API(selectedSystemFilter ? "PUT" : "POST", `${API_PATH.FILTER}${selectedSystemFilter ? `/${selectedSystemFilter.id}` : ""}`, params)
			.then((result) => {
				console.info("Result", result);
				// send back to parent
				onFilterApplied(result.data);
			})
			.catch((error) => {
				console.error("API Error:", error);
			});
	};

	const handleCancelFilter = () => {
		console.info("Cancel filter");
		onCancelFilter();
	};

	return (
		<section id="all-filters">
			<AllFilterWrapper>
				<FilterHeaderWrapper>
					{allowAI && <AIButtonComponent switchToAIFilter={switchToAIFilter} />}
					<SelectSearch id="system-filters" label="" placeholder="Select saved filter" options={systemFilters} value={selectedSystemFilter} handleValueChange={handleSystemFilterSelection} />
				</FilterHeaderWrapper>
				<FiltersWrapper>{filtersWithCallback && filtersWithCallback.length && filtersWithCallback.map((filter) => <div key={filter.accessorKey}>{renderFilterInput(filter)}</div>)}</FiltersWrapper>
				<FilterFooterWrapper>
					<InputText
						id="system-filter-name"
						label="Filter Name"
						placeholder="Filter Name"
						size="small"
						defaultValue={filterName}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value;
							setFilterName(value);
							if (selectedSystemFilter && selectedSystemFilter.name === value) {
								setIsDirty(false);
							} else {
								setIsDirty(true);
							}
						}}
					/>
					<ButtonGroup>
						<Button label="Cancel" size="small" variant="secondary" onClick={handleCancelFilter} />
						{/* disable Save & Apply button if filterName is empty || finalFilters is empty || filterName is same as selectedSystemFilter's name */}
						<Button label={selectedSystemFilter ? "Update & Apply" : "Save & Apply"} size="small" variant="primary" onClick={handleSaveFilter} disabled={!isDirty || !filterName || finalFilters.length === 0} />
					</ButtonGroup>
				</FilterFooterWrapper>
			</AllFilterWrapper>
		</section>
	);
}
export default AllFilters;
